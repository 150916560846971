export const environment = {
  production: false,
  environment: 'developpement',
  apiUrl: 'https://298qf7m3kd.execute-api.eu-west-1.amazonaws.com/developpement/v1',
  cloudFront: '',
  googleAnalyticsId: 'UA-131266439-8',
  authDefaults: {
    secretAccessKey: '',
    accessKeyId: '',
    sessionToken: '',
    awsRegion: '',
  },
  cms: {
    distributionChannel: '1I2JmDk7vmZDzuCXL4dKTY', // only CMS contents with this channel are authorized for Portail Star
    spaceId: 'ztehsn2qe34u',
    apiKey: 'M-cLt2u-V_V0FRRE_PVl8_WYjEZOYDMGjfzuCw21wjI',
    basepath: location.origin + '/star-app/edito/',
  },
  teregaLink: 'https://www.terega.fr/',
  teregaLinkEn: 'https://www.terega.fr/en/',
  tetraBaseHref: 'http://toulouse.sqli.com/SBT',
  prismaBaseHref: 'https://platform.prisma-capacity.eu',
  timezone: 'Europe/Paris',
  captcha: {
    siteKey: '6LefhqwUAAAAABlXdSY_fh2GGaPSbjmdCRmdSSU8',
  },
};
